import React, { Component } from 'react';
import { motion } from 'framer-motion';
import './loading.style.scss';

class Loading extends Component {
  render() {
    return (
      <motion.div
        className='loading'
        animate={{ x: '-100%' }}
        transition={{ duration: 1, type: 'tween', delay: 1 }}
      >
        <svg
          viewBox='0 -400 4754 1911'
          fillRule='evenodd'
          clipRule='evenodd'
          strokeLinejoin='round'
          strokeMiterlimit='2'
          className='loading__logo'
        >
          <path
            d='M4485 295.583V152.228h175.313v143.355h92.812v160.332h-92.812v418.748H4485V455.915h-91.25V295.583H4485ZM3942.08 874.663V543.626c0-55.645 46.928-101.858 103.242-101.858 76.962 0 90.102 45.27 90.102 93.369v339.526h180.205V476.664c0-109.403-92.918-192.399-207.423-192.399-112.628 0-168.004 86.768-168.004 86.768V152.228h-174.573v722.435h176.451ZM3081.29 591.337c0 161.975 109.465 286.547 262.461 286.547 70.244 0 129.17-34.37 163.811-65.407 1.925 15.519.962 29.098.962 44.616 0 64.984-57.734 94.082-120.28 94.082-44.263 0-69.281-7.76-94.299-48.496h-201.108c19.244 133.848 146.26 207.561 291.558 207.561 165.506 0 303.106-96.021 303.106-280.304V305.213h-171.279v66.924c-50.998-54.315-101.997-78.563-177.052-78.563-155.883 0-257.88 136.757-257.88 297.763Zm299.256-143.547c92.375 0 136.639 64.014 136.639 143.547 0 66.924-48.112 138.698-137.601 138.698-78.903 0-124.129-63.045-124.129-139.668 0-73.713 41.377-142.577 125.091-142.577Z'
            fillRule='nonzero'
          />
          <path d='M2828.12 293.574h175v581.09h-175z' />
          <motion.path
            d='M2812.5 106.182c0 58.046 44.917 103.193 100.833 103.193 58.667 0 105.417-46.99 105.417-105.036 0-57.125-46.75-102.272-103.583-102.272-56.834 0-102.667 46.99-102.667 104.115Z'
            fill='#bf616a'
            id='dot-js'
            transition={{ type: 'spring', stiffness: 100 }}
            initial={{ y: '-100%' }}
            animate={{ y: '0' }}
          />
          <path
            d='m2527.08 874.276-80.168-240.945 126.129-339.757h195.783L2527.08 874.276Z'
            fill='#448cb1'
          />
          <path d='m2446.91 633.331-.022-.01-79.987 241.34-241.904-581.087h195.783l126.13 339.757Z' />
          <path
            d='M1525 582.771c0 153.581 128.186 291.892 295.54 291.892 178.036 0 298.21-126.636 298.21-294.586 0-166.154-129.076-286.503-294.65-286.503-168.244 0-299.1 123.942-299.1 289.197Zm298.21-132.025c77.446 0 130.857 55.684 130.857 126.637 0 86.22-49.85 139.21-131.747 139.21-75.665 0-131.747-57.481-131.747-131.127 0-75.443 52.521-134.72 132.637-134.72ZM1073.33 874.663V548.845c0-54.767 46.928-100.252 103.242-100.252 76.962 0 90.102 44.556 90.102 91.897v334.173h180.205V482.938c0-107.678-92.918-189.364-207.423-189.364-112.628 0-168.004 85.399-168.004 85.399v-74.26H896.879v569.95h176.451ZM445.201 874.663V548.845c0-54.767 46.928-100.252 103.242-100.252 76.962 0 90.102 44.556 90.102 91.897v334.173H818.75V482.938c0-107.678-92.918-189.364-207.423-189.364-112.628 0-168.004 85.399-168.004 85.399v-74.26H268.75v569.95h176.451Z'
            fillRule='nonzero'
          />
          <path d='M15.625 293.574h175v581.09h-175z' />
          <motion.path
            d='M0 104.115c0 58.046 44.917 103.193 100.833 103.193 58.667 0 105.417-46.99 105.417-105.036C206.25 45.147 159.5 0 102.667 0 45.833 0 0 46.99 0 104.115Z'
            fill='#bf616a'
            transition={{ type: 'spring', stiffness: 100 }}
            initial={{ y: '-100%' }}
            animate={{ y: '0' }}
          />
        </svg>
      </motion.div>
    );
  }
}

export default Loading;
