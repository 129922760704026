import * as React from 'react';
import { Link } from 'gatsby';
import './latestworks.style.scss';
import { StaticImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}
class LatestWorks extends React.Component {
  componentDidMount() {
    gsap.to('.latest-works__heading', {
      opacity: 0,
      scrollTrigger: {
        trigger: '.latest-works__heading',
        start: 'top 0%',
        endTrigger: '.latest-works',
        end: 'bottom 50%',
        pin: true,
        scrub: true,
        markers: false,
        pinSpacing: false,
      },
    });
  }
  render() {
    return (
      <section className='latest-works'>
        <h2 className='latest-works__heading'>
          <Link title='Latest Works' className='transform-scale-h' to='/works'>
            Latest <span className='line'>Works</span>
          </Link>
        </h2>
        <div className='showcase showcase--metroland'>
          <Link title='Metroland Photo' to='/works/metroland-photo/'>
            <StaticImage
              width={900}
              quality={95}
              formats={['auto', 'webp', 'avif']}
              src='../../images/works/showcase--metroland.jpg'
              alt='Mockup of Metroland Photo on Iphone'
            />
            <div className='showcase--text'>
              <h2>A Fine-Art Photographer based In Albany, NY.</h2>
              <h3>
                A modern Website design centered around beautiful photography. Metroland Photo.
              </h3>
              <h4>Web Design | Development | Logo Design</h4>
            </div>
          </Link>
        </div>
        <div className='showcase showcase--fritz'>
          <Link title='Fr. Michael Fritz' to='/works/fritz/'>
            <div className='showcase--text'>
              <h2>Creating a New Identity.</h2>
              <h3>New Branding and Stationary Design for Fr. Michael Fritz.</h3>
              <h4>Branding | Logo Design | Stationary Design</h4>
            </div>
            <StaticImage
              width={900}
              quality={95}
              formats={['auto', 'webp', 'avif']}
              src='../../images/works/showcase--fritz.png'
              alt='Michael Fritz Letterhead Sample Card'
            />
          </Link>
        </div>
        <div className='showcase showcase--90state'>
          <Link title='Metroland Photo' to='/works/90-state-events/'>
            <StaticImage
              width={900}
              quality={95}
              formats={['auto', 'webp', 'avif']}
              src='../../images/works/showcase--90state.jpg'
              alt='Mockup of Metroland Photo on Iphone'
            />
            <div className='showcase--text'>
              <h2>A new website for 90 State Events in Albany, NY.</h2>
              <h3>Built to match the high standards and quality of the events they produce.</h3>
              <h4>Web Design | Development | SEO</h4>
            </div>
          </Link>
        </div>
        <div className='showcase showcase--mazzonecatering'>
          <Link title='Metroland Photo' to='/works/mazzone-catering/'>
            <StaticImage
              width={900}
              quality={95}
              formats={['auto', 'webp', 'avif']}
              src='../../images/works/showcase--mazzonecatering.jpg'
              alt='Mockup of Metroland Photo on Iphone'
            />
            <div className='showcase--text'>
              <h2>A trendy redesigned website for Mazzone Catering in Saratoga.</h2>
              <h3>Creating a innovative website for true culinary innovators.</h3>
              <h4>Web Design | Development | SEO</h4>
            </div>
          </Link>
        </div>
      </section>
    );
  }
}

export default LatestWorks;
