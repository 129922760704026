import * as React from 'react';
import './landinggrid.style.scss';

const LandingGrid = () => (
  <section className='section landing__grid'>
    <h2>Who We Are</h2>
    <h3 className='landing__grid__headline'>
      For over a decade we have been bringing clients <span className='line'>innov</span>ative
      designs done r<span className='line'>ight</span>, all while building customer experiences
      that are both beautiful and easy to use
      <span className='dot'>.</span>
    </h3>
    <p className='landing__grid__text'>
      Inquiries:
      <br />
      <a href='mailto:hello@innovight.com' className='transform-scale-h landing__grid__text__link'>
        hello@innovight.com
      </a>
      <br />
      <a
        href='tel:518-290-8338'
        aria-label='5 1 8. 2 9 0. 8 3 3 8.'
        className='transform-scale-h landing__grid__text__link'
      >
        518-290-8338
      </a>
    </p>
  </section>
);

export default LandingGrid;
